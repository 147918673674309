import * as React from "react"
import Layout from "../components/layout"
import SelfieImg from "../images/selfie.jpg"

export default function Home() {
  return <Layout>
  <div class="content">
    <img src={SelfieImg} alt="Me" />
  </div>
  </Layout>
}